// Simple implementation of lodash.get
// Handles arrays, objects, and any nested combination of the two.
// Also handles undefined as a valid value - see test case for details.
export const deepGet = (
    obj: Record<string, any>,
    query: string | Array<any>,
    defaultVal: any = undefined
): any => {
    query = Array.isArray(query)
        ? query
        : query
              .replace(/(\[(\d)\])/g, ".$2")
              .replace(/^\./, "")
              .split(".");
    if (!(query[0] in obj)) {
        return defaultVal;
    }
    obj = obj[query[0]];
    if (obj && query.length > 1) {
        return deepGet(obj, query.slice(1), defaultVal);
    }
    return obj;
};
