import { Box } from "@mui/system";
import jwtDecode from "jwt-decode";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { AdminUser, adminUserAtom } from "../global-state/user.atom";
import { decodeSigninToken } from "../utils/decode-signin-tokens";
import { saveUserTokensFromCodeAndReturnIdToken } from "../utils/save-user-cookie-from-code";

export const LoginAuthPage: React.FC = () => {
    const code = decodeSigninToken();

    const setUserAtom = useSetRecoilState(adminUserAtom);
    const navigate = useNavigate();

    useEffect(() => {
        const handleLoginToken = async () => {
            try {
                if (code) {
                    const idToken =
                        await saveUserTokensFromCodeAndReturnIdToken();
                    if (!idToken) {
                        console.error("No id token");
                        // return;
                        return window.close();
                    }

                    const userObject = jwtDecode(idToken);

                    // save user state
                    setUserAtom(userObject as AdminUser);

                    // if redirected from popup, close and refersh opener
                    if (window.opener) {
                        window.opener.window.location.reload();
                        window.close();
                    }
                } else {
                    console.error("no code");
                    if (window.opener) {
                        window.opener.window.location.reload();
                        window.close();
                    }
                    navigate("/");
                }
            } catch (e) {
                console.error("e", e);
            }
        };

        handleLoginToken();
    }, [code, setUserAtom, navigate]);

    if (code) {
        return (
            <Box sx={{ display: "flex", width: "600", height: "600" }}>
                <CircularProgress />
            </Box>
        );
    }

    return <div>Error logging in</div>;
};
