import { Box, Divider } from "@mui/material";
import React, { FormEvent, useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import { getEnvironment } from "../utils/get-environment";

// defaults
const HEADERS = {
    Accept: "application/json",
    "Content-Type": "application/json",
}

// Endpoint
const getApiEndpoint = (): string => {
   const environment = getEnvironment();
   return `https://${environment}.kamoa.services/sms`
}

const formatTimestamp = (timestamp: number) => {
    const date = new Date(timestamp); // Convert to milliseconds

    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    }).format(date);
}

const makeRequest = async (endpoint: string, method = "GET", data = {}, headers = {}) => {
    const methods = ["PUT", "POST", "PATCH"];
    const BASE_URL = getApiEndpoint()

    return fetch(`${BASE_URL}${endpoint}`, {
        headers: { ...HEADERS, ...headers },
        method: method,
        ...(methods.indexOf(method) >= 0 ? {body: JSON.stringify(data)} : {})
    })
}


// types
enum JobStatus {
    Pending = 0,
    Processing,
    Failed,
    Completed,
}

interface SmsJob {
    id: string;
    failedCount: number;
    fileKey: string;
    recordCount: number;
    sentCount: number;
    jobStatus: JobStatus;
    status: string;
    template: string;
    timestamp: number;
    variables: string[];
}

// styled components
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const VariablesContainer = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    bgcolor: "#e0e0e0",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

const modalBoxStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const AddTemplateModal = ({job, open, setOpen}: {job: SmsJob, open: boolean; setOpen: (state?: boolean) => Promise<void>}) => {
    const [savingTemplate, setSavingTemplate] = useState<boolean>(false)
    const [templateInput, setTemplateInput] = useState<string>(job.template ?? "");
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false)
    const [snackBarMessage, setSnackBarMessage] = useState<string>('')
    const variables = (job.variables ?? []).map((variable) => {
        return Object.values(variable).join(", ")
    }).join(", ");

    const handleClose = () => setOpen(false);

    const handleTemplateInput = (event: FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        console.log(target.value)
        setTemplateInput(target.value ?? "");
    }

    const handleSaveTemplateClick = async () => {
        setSavingTemplate(true);
        const response = await makeRequest("/sms-template", "POST", {
            jobId: job.id,
            template: templateInput,
        })

        if (response.ok) {
            setSnackBarMessage("Template successfully saved!");
            await setOpen(true)
        } else {
            setSnackBarMessage("An error occurred saving template!");
        }

        setSavingTemplate(false);
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalBoxStyle}>
                    <Typography id="modal-modal-title" variant="h6">
                        Available variables
                    </Typography>

                    <VariablesContainer elevation={0}>
                        {variables}
                    </VariablesContainer>

                    <Divider />

                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': {  minWidth: 650, marginTop: 5 },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField id="outlined-basic"
                                   label="Create Template"
                                   defaultValue={job.template ?? templateInput}
                                   onInput={handleTemplateInput}
                                   variant="outlined"
                        />

                        <Stack spacing={2} direction="row" style={{marginTop: "15px"}}>
                            <Button disabled={savingTemplate} variant="contained" onClick={handleSaveTemplateClick}>
                                {savingTemplate ? "Saving..." : "Save"}
                            </Button>
                            <Button variant="text" onClick={() => setOpen()}>Cancel</Button>
                        </Stack>

                    </Box>
                </Box>
            </Modal>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={showSnackBar}
                autoHideDuration={6000}
                onClose={() => setShowSnackBar(false)}
                message={snackBarMessage}
            />
        </div>
    );
}

const BulkSmsPage = () => {
    const [smsJobs, setSMSJobs] = useState<SmsJob[]>([])
    const [addTemplateOpen, setAddTemplateOpen] = useState<boolean>(false)
    const [uploading, setUploading] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false)
    const [snackBarMessage, setSnackBarMessage] = useState<string>('')
    const [jobStarted, setJobStarted] = useState<boolean>(false)
    const [selectedJob, setSelectedJob] = useState<SmsJob>({} as SmsJob)

    const handleAddTemplateClick = (job: SmsJob) => {
        setSelectedJob(job);
        setAddTemplateOpen(true)
    }

    const handleCloseModal = async (state?: boolean) => {
        setAddTemplateOpen(false);
        setSelectedJob({} as SmsJob);

        if (state) {
            // NOTE: This is not ideal. We only want to fetch update state of the changed record
            const fetchJobs = await makeRequest("/jobs")
            const fetchJobsResponse = await fetchJobs.json();
            setSMSJobs(fetchJobsResponse.data)
        }
    }

    const handleDeleteClick = async (job: SmsJob) => {
        setSelectedJob(job);
        setDeleting(true)
        const deleteJobResponse = await makeRequest(`/job?jobId=${job.id}`, "DELETE")

        if (deleteJobResponse.ok) {
            // NOTE: This is not ideal. We only want to fetch update state of the changed record
            const fetchJobs = await makeRequest("/jobs")
            const fetchJobsResponse = await fetchJobs.json();
            setSMSJobs(fetchJobsResponse.data)
        }

        setSelectedJob({} as SmsJob);
        setDeleting(false)
    }

    const handleJobStartClick = async (job: SmsJob) => {
        setJobStarted(true);
        setSelectedJob(job);

        try {
            const response = await makeRequest("/bulk", "POST", {
                jobId: job.id,
                fileKey: job.fileKey,
                template: job.template,
                variables: (job.variables ?? []).map((variable) => {
                    return Object.values(variable).join(",")
                }),
            })

            if (response.ok) {
                // NOTE: This is not ideal. We only want to fetch update state of the changed record
                const fetchJobs = await makeRequest("/jobs")
                const fetchJobsResponse = await fetchJobs.json();

                setSMSJobs(fetchJobsResponse.data);
                setSnackBarMessage("Job successfully completed");
                setShowSnackBar(true);
            } else {
                setSnackBarMessage("Failed to process job");
                setShowSnackBar(true);
            }
        } catch (e) {
            console.error(e)
            setSnackBarMessage("Failed to process job");
            setShowSnackBar(true);
        } finally {
            setJobStarted(false);
            setSelectedJob({} as SmsJob);
        }

    }

    const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;

        if (file) {
            setUploading(true)
            const reader = new FileReader();
            const filename = file.name;

            reader.onload = async (e: ProgressEvent<FileReader>)=> {
                // get presigned url for file upload
                const signedURLRequest = await makeRequest(`/presigned-url?filename=${filename}`)
                const { signedURL, fileKey } = await signedURLRequest.json()

                if (signedURL && fileKey) {
                    // upload file
                    const fileUploadOptions = {
                        method: 'PUT',
                        body: file,
                        headers: {
                            'Content-Type': 'binary/octet-stream'
                        },
                    }
                    const uploadFileRequest = await fetch(signedURL, fileUploadOptions)

                    if (uploadFileRequest.ok) {
                        // get headers from csv, to be used as varibles
                        const decoder = new TextDecoder("utf-8");
                        const fileContent = decoder.decode(e.target!.result as ArrayBuffer);
                        const headers = fileContent.split('\n')[0]
                            .split(',')
                            .map((header) => header.replace(/^['"]|['"]$/g, "").trim());

                        // create job and update list of jobs
                        const request = await makeRequest("/job", "POST", {fileKey, headers})
                        if (request.ok) {
                            const fetchJobs = await makeRequest("/jobs")
                            const fetchJobsResponse = await fetchJobs.json();
                            setSMSJobs(fetchJobsResponse.data)
                        }
                    }
                }

                setUploading(false)
                event.target.files = null
            }

            // Read only the first chunk of the file
            const chunk = file.slice(0, 1024);
            reader.readAsArrayBuffer(chunk);
        }
    }

    useEffect(() => {
        (async () => {
            const request = await makeRequest("/jobs")
            const jsonResponse = await request.json();

            if ("data" in jsonResponse) {
                setSMSJobs(jsonResponse.data)
            }
        })()
    }, []);

    return (
        <>
            <Box sx={{ padding: "2em" }}>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    disabled={uploading}
                >
                    {uploading ? 'Uploading...' : 'Upload CSV'}
                    <VisuallyHiddenInput type="file"
                                         onChange={handleFileInputChange}
                                         accept=".csv"
                    />
                </Button>
                <br />
                <TableContainer component={Paper} sx={{ marginTop: 5 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Key</TableCell>
                                <TableCell align="right">Created on</TableCell>
                                <TableCell align="right">Record Count</TableCell>
                                <TableCell align="right">Failed</TableCell>
                                <TableCell align="right">Processed</TableCell>
                                <TableCell align="right">Status</TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {smsJobs.map((job) => (
                                <TableRow
                                    key={job.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {job.fileKey}
                                    </TableCell>
                                    <TableCell align="right">{formatTimestamp(parseInt(job.timestamp as unknown as string))}</TableCell>
                                    <TableCell align="right">{job.recordCount}</TableCell>
                                    <TableCell align="right">{job.failedCount}</TableCell>
                                    <TableCell align="right">{job.sentCount}</TableCell>
                                    <TableCell align="right">{job.jobStatus ? JobStatus[job.jobStatus] : job.status }</TableCell>
                                    <TableCell align="right">
                                        {JobStatus[job.jobStatus] === "Pending" && (
                                            <Stack spacing={2} direction="row">
                                                <Button variant="text" onClick={() => handleAddTemplateClick(job)}>
                                                    {job.template ? "Modify" : "Add"} Template
                                                </Button>

                                                {job.template && (
                                                    <Button disabled={selectedJob.id === job.id && jobStarted}
                                                            variant="contained"
                                                            onClick={() => handleJobStartClick(job)}
                                                    >
                                                        Start Job
                                                    </Button>
                                                )}
                                            </Stack>
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Stack spacing={2} direction="row">
                                            <Button variant="outlined"
                                                    color="error"
                                                    disabled={deleting && selectedJob.id === job.id}
                                                    onClick={() => handleDeleteClick(job)}
                                            >
                                                DELETE
                                            </Button>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <AddTemplateModal job={selectedJob} open={addTemplateOpen} setOpen={handleCloseModal} />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={showSnackBar}
                autoHideDuration={6000}
                onClose={() => setShowSnackBar(false)}
                message={snackBarMessage}
            />
        </>
    );
};

export default BulkSmsPage;
