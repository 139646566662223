import { ZodError } from "zod";

type ErrorsForCondition =
    | {
          attribute?: string;
          value?: string;
          operator?: string;
      }
    | undefined;

export const getErrorsForCondition = (
    conditionIndex: number,
    zodError: ZodError | null
): ErrorsForCondition => {
    if (!zodError) {
        return;
    }

    const matchingErrors = zodError.errors.filter(
        (issue) => issue.path[0] === conditionIndex
    );

    if (!matchingErrors.length) {
        return;
    }

    const errorObject: ErrorsForCondition = {};
    matchingErrors.forEach((issue) => {
        errorObject[issue.path[1] as unknown as keyof typeof errorObject] =
            issue.message;
    });
    return errorObject;
};
