import { FormHelperText } from "@mui/material";
import React from "react";
import Select, { SingleValue } from "react-select";

type UserFieldOption = {
    label: string;
    value: string;
};

type Props = {
    options: UserFieldOption[];
    placeholder?: string;
    value: string;
    error?: string;
    onChange: (value: string) => void;
};

export const CustomReactSelect: React.FC<Props> = ({
    options,
    placeholder,
    value,
    error,
    onChange,
}) => {
    const propsValueOption = value
        ? {
              label: value,
              value: value,
          }
        : null;

    const handleChange = (singleValue: SingleValue<UserFieldOption>) => {
        onChange(singleValue?.value ?? "");
    };

    return (
        <>
            <Select
                styles={{
                    valueContainer: (base) => ({
                        ...base,
                        padding: ".90em",
                    }),
                    control: (base) => ({
                        ...base,
                        borderColor: error ? "red" : "lightgray",
                        "&:hover": {
                            cursor: "pointer",
                            borderColor: error ? "red" : "black",
                        },
                    }),
                }}
                value={propsValueOption}
                //@ts-ignore
                options={options}
                placeholder={placeholder}
                //@ts-ignore
                onChange={handleChange}
            />
            <FormHelperText
                style={{
                    color: "red",
                }}
            >
                {error ? error : ""}
            </FormHelperText>
        </>
    );
};
