import { getEnvironment } from "./get-environment";

// client ids
const devClientId = "7mumkdi802j12oe1tgv93erb2s";
const prodClientId = "3sp5raleh3oa9cbq9tgru5s035";

export const getHostedUiUrl = (): string => {
    const environment = getEnvironment();
    const isLocalhost = window.location.hostname === "localhost";

    const clientId =
        isLocalhost || environment === "dev" ? devClientId : prodClientId;

    const redirectUrl = isLocalhost
        ? "http://localhost:3000/login-auth"
        : `https://support.${environment}.kamoa.services/login-auth`;

    return `https://sso-${environment}-kamoa-services.auth.eu-west-2.amazoncognito.com/login?client_id=${clientId}&response_type=code&scope=email+openid+phone&redirect_uri=${redirectUrl}`;
};

export const getOauthInfo = () => {
    const environment = getEnvironment();
    const isLocalhost = window.location.hostname === "localhost";

    const clientId =
        isLocalhost || environment === "dev" ? devClientId : prodClientId;

    return {
        clientId,
        url: `https://sso-${environment}-kamoa-services.auth.eu-west-2.amazoncognito.com/oauth2/token`,
    };
};
