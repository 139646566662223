import { atom } from "recoil";
import z from "zod";

const conditionSchema = z.object({
    attribute: z.string().min(1, "Attribute is Required"),
    operator: z.string().min(1, "Operator is Required"),
    value: z
        .string()
        .min(1, "Value is Required")
        .or(z.number())
        .or(z.boolean())
        .or(z.null()),
});

export const multipleConditionsSchema = z.array(conditionSchema);

export type Condition = z.infer<typeof conditionSchema>;

export const conditionsAtom = atom<Condition[]>({
    key: "conditions",
    default: [
        {
            attribute: "",
            operator: "",
            value: "",
        },
    ],
});
