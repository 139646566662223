import { getOauthInfo } from "./get-hosted-ui-url";

export const saveUserTokensFromCodeAndReturnIdToken = async () => {
    // get href
    const href = window.location.href;
    // get access token
    const code = href.split("code=")?.[1];
    console.log("code", code);

    if (!code) {
        return;
    }
    // get tokens from aws cognito
    const { idToken, accessToken, refreshToken } = await getTokensForCode(code);

    const date30DaysFromNow = new Date();
    date30DaysFromNow.setTime(new Date().getTime() + 30 * 24 * 60 * 60 * 1000);

    document.cookie = `id_token=${idToken}; expires=${date30DaysFromNow.toUTCString()}; path=/`;
    document.cookie = `access_token=${accessToken}; expires=${date30DaysFromNow.toUTCString()}; path=/`;
    document.cookie = `refresh_token=${refreshToken}; expires=${date30DaysFromNow.toUTCString()}; path=/`;

    return idToken;
};

export const getTokensForCode = async (
    code: string
): Promise<{
    idToken: string;
    accessToken: string;
    refreshToken: string;
}> => {
    const { clientId, url } = getOauthInfo();
    console.log("url", url);
    console.log("clientId", clientId);

    const res = await fetch(url, {
        method: "POST",
        headers: new Headers({
            "content-type": "application/x-www-form-urlencoded",
        }),
        body: Object.entries({
            grant_type: "authorization_code",
            client_id: clientId,
            code: code,
            redirect_uri: `${window.location.origin}/login-auth`,
        })
            .map(([k, v]) => `${k}=${v}`)
            .join("&"),
    });
    if (!res.ok) {
        throw new Error(await res.text());
    }

    const tokens = await res.json();
    return {
        idToken: tokens.id_token,
        accessToken: tokens.access_token,
        refreshToken: tokens.refresh_token,
    };
};
