export enum Nationality {
    Kenya = "Kenya",
}

export enum Title {
    Mr = "Mr",
    Mrs = "Mrs",
    Miss = "Miss",
    Ms = "Ms",
}

export enum DocumentType {
    NationalID = "NATIONAL_ID",
    Passport = "PASSPORT",
}

export enum SmileIDResultText {
    PartialMatch = "Partial Match",
    ExactMatch = "Exact Match",
    NoMatch = "No Match",
}

export enum AuthorityDatabaseKey {
    Verified = "Verified",
    NotVerified = "Not Verified",
    NotDone = "Not Done",
    IssuerUnavailable = "Issuer Unavailable",
}

export enum MoodCurrentFinancial {
    VeryWorried = "Very worried",
    Worried = "Worried",
    NotTooWorried = "Not too worried",
    Okay = "Okay",
    PrettyGood = "Pretty good",
    VeryGood = "Very good",
}

export enum MoodPersonalFinanceChange {
    WillGetWorse = "It will get worse",
    WontChange = "It wont change",
    NotSure = "I am not sure",
    MightImprove = "It might improve",
    CanGetBetter = "It can get better with the right help",
    WillGetBetter = "It will get better",
}

export enum MaritalStatus {
    Single = "Single",
    LivingWithPartner = "Living with Partner",
    Married = "Married",
    Divorced = "Divorced",
    CivilPartnership = "Civil Partnership",
    Other = "Other",
}

export enum EducationLevel {
    PrimarySchool = "Primary School",
    SecondarySchool = "Secondary School",
    University = "University",
    Other = "Other",
    RatherNotSay = "Rather not say",
}

export enum ApplicationStatus {
    Requested = "requested",
    Approved = "approved",
    Live = "live",
    Closed = "closed",
}

export enum Lender {
    Izwe = "IZWE",
}

//
//
//

interface Onboarding {
    readonly hasPersonalDetails: boolean;
    readonly hasWhatsYourGoals: boolean;
}

interface MoneyPersonality {
    readonly timestamp?: number;
    readonly result?: [number, number, number];
    readonly score?: number;
    readonly text?: string;
}

interface IdentityDocument {
    readonly idNumber: string;
    readonly idType: DocumentType;
}

interface KYC {
    readonly timestamp: number;
    readonly isValid: boolean;
    readonly resultType: string; //"ID Verification";
    readonly resultText: SmileIDResultText;
    readonly resultCode: number;
    readonly isFinalResult: boolean;
    readonly verifyIDNumber: AuthorityDatabaseKey;
}

export interface MoneyOut {
    readonly foodMonthlyExpenses: number;
    readonly gamblingMonthlyExpenses: number;
    readonly housingMonthlyExpenses: number;
    readonly utilitiesMonthlyExpenses: number;
    readonly howManyLoansBeenTakenLastSixMonth: number;
    readonly internetMonthlyExpenses: number;
    readonly isOnDebtManagementPlan: boolean;
    readonly moneyOweAmount: number;
    readonly moneyOwePaidBackMonthly: number;
    readonly loanProvider?: string[];
    readonly moneyOweToLoanApp?: string[];
}

export interface MoneyIn {
    readonly employmentType: string;
    readonly employmentBusinessAddress?: string;
    readonly employmentBusinessIndustry?: string;
    readonly employmentBusinessName?: string;
    readonly employmentMonthsWorking: number;
    readonly employmentYearsWorking: number;
    readonly employmentNextPayday: string;
    readonly employmentPaymentFrequency: string;
    readonly employmentTotalAmountEarndLastMonth: number;
    readonly otherIncomeAmountLastMonth: number;
    readonly otherSourceOfIncome?: string;
}

export interface PersonalSituation {
    readonly educationLevel: EducationLevel;
    readonly maritalStatus: MaritalStatus;
    readonly moodCurrentPersonalFinance: MoodCurrentFinancial;
    readonly moodPersonalFinanceWillChange: MoodPersonalFinanceChange;
    readonly nationality: Nationality;
    readonly numberOfDependents: number;
}

export interface FinancialSituation {
    readonly moneyIn: MoneyIn;
    readonly moneyInStatus: boolean;
    readonly moneyOut: MoneyOut;
    readonly moneyOutStatus: boolean;
    readonly personalSituation: PersonalSituation;
    readonly personalSituationStatus: boolean;
}

//
//
//

export interface User {
    readonly id: string;
    readonly currentLoginTimestamp: number;
    readonly lastLoginTimestamp: number;
    readonly registrationTimestamp: number;
    readonly phoneNumber: string;
    readonly email?: string;
    readonly title: Title;
    readonly firstName: string;
    readonly lastName: string;
    readonly middleName?: string;
    readonly dateOfBirth: string;
    readonly fullAddress?: string;
    readonly identityDocument: IdentityDocument;
    readonly kyc: KYC;
    readonly onboarding: Onboarding;
    readonly whatsYourGoal: string[];
    readonly moneyPersonality: MoneyPersonality | {};
    readonly financialSituation: FinancialSituation;
}

export const userPropertiesForQuerying = [
    "email",
    "phoneNumber",
    "firstName",
    "lastName",
    "deviceUniqueId",
];

export const userProperties = [
    "id",
    "currentLoginTimestamp",
    "lastLoginTimestamp",
    "registrationTimestamp",
    "phoneNumber",
    "email",
    "title",
    "firstName",
    "middleName",
    "lastName",
    "deviceUniqueId",
    "dateOfBirth",
    "fullAddress",
    "identityDocument",
    "kyc",
    "whatsYourGoal",
    "moneyPersonality",
    "onboarding",
    "financialSituation",
];

export const userPropertiesDetailed = [
    "id",
    "currentLoginTimestamp",
    "lastLoginTimestamp",
    "registrationTimestamp",
    "firstName",
    "lastName",
    "deviceUniqueId",
    "title",
    "dateOfBirth",
    "phoneNumber",
    "email",
    "fullAddress",
    "identityDocument.idNumber",
    "identityDocument.idType",
    "kyc",
    "kyc.isValid",
    "kyc.resultType",
    "kyc.resultText",
    "kyc.resultCode",
    "kyc.verifyIDNumber",
    "kyc.isFinalResult",
    "onboarding.hasPersonalDetails",
    "onboarding.hasWhatsYourGoals",
    "whatsYourGoal",
    "financialSituation.personalSituationStatus",
    "financialSituation.moneyOutStatus",
    "financialSituation.moneyInStatus",
    "financialSituation.personalSituation.educationLevel",
    "financialSituation.personalSituation.maritalStatus",
    "financialSituation.personalSituation.moodCurrentPersonalFinance",
    "financialSituation.personalSituation.moodPersonalFinanceWillChange",
    "financialSituation.personalSituation.nationality",
    "financialSituation.personalSituation.numberOfDependents",
    "financialSituation.moneyIn.employmentType",
    "financialSituation.moneyIn.employmentBusinessAddress",
    "financialSituation.moneyIn.employmentBusinessIndustry",
    "financialSituation.moneyIn.employmentBusinessName",
    "financialSituation.moneyIn.employmentMonthsWorking",
    "financialSituation.moneyIn.employmentNextPayday",
    "financialSituation.moneyIn.employmentPaymentFrequency",
    "financialSituation.moneyIn.employmentTotalAmountEarndLastMonth",
    "financialSituation.moneyIn.otherIncomeAmountLastMonth",
    "financialSituation.moneyIn.otherSourceOfIncome",
    "financialSituation.moneyOut.housingMonthlyExpenses",
    "financialSituation.moneyOut.internetMonthlyExpenses",
    "financialSituation.moneyOut.moneyOweAmount",
    "financialSituation.moneyOut.howManyLoansBeenTakenLastSixMonth",
    "financialSituation.moneyOut.utilitiesMonthlyExpenses",
    "financialSituation.moneyOut.gamblingMonthlyExpenses",
    "financialSituation.moneyOut.moneyOwePaidBackMonthly",
    "financialSituation.moneyOut.foodMonthlyExpenses",
    "financialSituation.moneyOut.isOnDebtManagementPlan",
    "moneyPersonality.timestamp",
    "moneyPersonality.result",
    "moneyPersonality.result",
    "moneyPersonality.result",
    "moneyPersonality.score",
    "moneyPersonality.text",
    "requests",
    "loans",
    "instalments",
    "payments",
];
