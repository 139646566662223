import {
    CircularProgress,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { User } from "../../types";
import { deepGet } from "../../utils/deep-get";
import { deepTransformTimeStampToDate } from "../../utils/deep-transform-timestamp";

type Props = {
    users: User[];
    isLoading: boolean;
    totalUsersCount: number;
    userProperties: string[];
    page: number;
    rowsPerPage: number;
    handleChangePage: (event: unknown, newPage: number) => void;
    handleChangeRowsPerPage: (
        event: React.ChangeEvent<HTMLInputElement>
    ) => void;
};

const getColumnValue = (
    value: string | number | Record<string, any>,
    columnName: string
) => {
    if (
        typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean"
    ) {
        if (columnName.toLowerCase().includes("timestamp")) {
            return new Date(value).toLocaleString();
        }
        if (columnName === "id") {
            return (
                <Link to={`/${value}`}>
                    {value.toString().substring(0, 7) + "..."}
                </Link>
            );
        }
        return value.toString();
    }
    if (Array.isArray(value)) {
        return value.join(", ");
    }
    const text = JSON.stringify(deepTransformTimeStampToDate(value));
    // add ...
    return text.length > 24 ? text.slice(0, 24) + "...}" : text;
};

export const UsersTable: React.FC<Props> = ({
    users,
    totalUsersCount,
    userProperties,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    isLoading,
}) => {
    if (isLoading) {
        return (
            <Stack
                flexDirection="row"
                style={{ width: "100%", marginTop: "2em" }}
                justifyContent="center"
            >
                <CircularProgress />
            </Stack>
        );
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {userProperties.map((column) => (
                            <TableCell key={column}>{column}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (
                        <TableRow key={user.id}>
                            {userProperties.map((column) => (
                                <TableCell key={column}>
                                    {getColumnValue(
                                        deepGet(user, column) as
                                            | string
                                            | number
                                            | Record<string, any>,
                                        column
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
                component="div"
                count={totalUsersCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    );
};
