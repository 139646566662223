import { Button } from "@mui/material";
import { getHostedUiUrl } from "../utils/get-hosted-ui-url";

const hoistedUrl = getHostedUiUrl();

function popupWindowInCenter(
    url: string,
    windowName: string,
    win: Window,
    w: number,
    h: number
) {
    const y = win.top!.outerHeight / 2 + win.top!.screenY - h / 2;
    const x = win.top!.outerWidth / 2 + win.top!.screenX - w / 2;

    return win.open(
        url,
        windowName,
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
    );
}

export const GoogleLoginButton = () => {
    return (
        <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={() => {
                popupWindowInCenter(
                    hoistedUrl,
                    "google-login",
                    window,
                    600,
                    600
                );
                return false;
            }}
        >
            Login with Google
        </Button>
    );
};
