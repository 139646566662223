import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
// import ReactJson from "react-json-view";
import {
    Box,
    Button,
    FormControl,
    Stack,
    TextField,
    Typography,
} from "@mui/material";

import {
    getUserById,
    KycAction,
    makeKycAction,
} from "../components/users-table-section/get-users";
import { User } from "../types";
import { deepTransformTimeStampToDate } from "../utils/deep-transform-timestamp";

export const UserDetailsPage: React.FC = () => {
    const params = useParams();
    const userId = params.userId;
    const [user, setUser] = React.useState<User | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const getUser = React.useCallback(async () => {
        if (!userId) {
            return;
        }

        const user = await getUserById(userId);
        setUser(user);
    }, [userId]);

    useEffect(() => {
        getUser();
    }, [userId]);

    const onExactMatchPress = async () => {
        setIsLoading(true);
        if (!userId) {
            console.log("NO USER ID!");
            return;
        }

        await makeKycAction(userId, KycAction.EXACT_MATCH);
        await getUser();
        setIsLoading(false);
    };

    const onNoMatchPress = async () => {
        setIsLoading(true);
        if (!userId) {
            console.log("NO USER ID!");
            return;
        }

        await makeKycAction(userId, KycAction.NO_MATCH);
        await getUser();
        setIsLoading(false);
    };

    const onResetPress = async () => {
        setIsLoading(true);
        if (!userId) {
            console.log("NO USER ID!");
            return;
        }

        await makeKycAction(userId, KycAction.RESET);
        await getUser();
        setIsLoading(false);
    };

    const isKycValid = user?.kyc?.isValid ?? false;

    return (
        <Box style={{ padding: "2em" }}>
            <Stack
                style={{ paddingBottom: "2rem" }}
                direction="row"
                alignItems="center"
            >
                <Typography variant="h3">User Details</Typography>
                <Link
                    to="/"
                    style={{ textDecoration: "none", marginLeft: "2rem" }}
                >
                    <Button variant="contained"> Back to Users</Button>
                </Link>
            </Stack>
            <Stack sx={{ marginBottom: "2em" }}>
                <FormControl>
                    {isKycValid ? (
                        <Typography variant="h4" sx={{ marginBottom: "1em" }}>
                            Kyc is already valid
                        </Typography>
                    ) : null}
                    <Button
                        sx={{ marginBottom: "2em" }}
                        disabled={isLoading || isKycValid}
                        variant="contained"
                        color="success"
                        onClick={onExactMatchPress}
                    >
                        Set KYC - Exact Match
                    </Button>
                    <Button
                        sx={{ marginBottom: "2em" }}
                        disabled={isLoading || isKycValid}
                        variant="contained"
                        color="error"
                        onClick={onNoMatchPress}
                    >
                        Set KYC - No Match
                    </Button>
                    <Button
                        disabled={isLoading || isKycValid}
                        variant="contained"
                        onClick={onResetPress}
                        sx={{ marginBottom: "1em" }}
                    >
                        Reset KYC
                    </Button>
                </FormControl>
            </Stack>
            {user ? (
                <pre>
                    {JSON.stringify(
                        deepTransformTimeStampToDate(user),
                        null,
                        4
                    )}
                </pre>
            ) : // <ReactJson
            //     style={{
            //         fontSize: "1rem",
            //         marginTop: ".5rem",
            //         padding: "1.5rem 1rem",
            //     }}
            //     collapseStringsAfterLength={false}
            //     displayObjectSize={false}
            //     displayDataTypes={false}
            //     theme="tube"
            //     indentWidth={8}
            //     src={deepTransformTimeStampToDate(user)}
            //     enableClipboard={(copy) => {
            //         // copy strings directly without quotes
            //         if (typeof copy.src === "string") {
            //             navigator.clipboard.writeText(copy.src);
            //         }
            //     }}
            //     collapsed={2}
            //     name={"user"}
            //     quotesOnKeys={false}
            // />
            null}
        </Box>
    );
};
