import { Condition } from "../../global-state/conditions.atom";
import { ResultsCache } from "../../global-state/results-cache.atom";
import { User } from "../../types";
import { getAccessToken } from "../../utils/get-access-token";
import { getApiEndpoint, getKycEndpoint } from "../../utils/get-api-endpoint";
import { refreshUserTokens } from "../../utils/refresh-tokens";

const authHeaders = (token?: string) => ({
    authorization: token || "",
});

const filterEmptyConditions = (conditions: Condition[]) => {
    return conditions.filter((condition) => {
        return condition.attribute !== "";
    });
};

const addParamsToRequest = ({
    limit,
    lastKey,
    conditions,
}: {
    limit: number;
    lastKey: string | null;
    conditions: Condition[];
}) => {
    const params = new URLSearchParams();

    // don't add limit if there are conditions
    if (conditions.length > 0) {
        conditions.forEach((condition) => {
            params.append("attribute", condition.attribute);
            params.append("operator", condition.operator);
            params.append("value", condition.value!.toString());
        });
    } else {
        params.append("limit", limit.toString());

        if (lastKey) {
            params.append("lastKey", lastKey);
        }
    }

    const usersEndpoint = getApiEndpoint();
    return `${usersEndpoint}?${params.toString()}`;
};

const getCacheKeyFromConditions = (conditions: Condition[]) => {
    return conditions
        .map(
            (condition) =>
                `${condition.attribute}+${condition.operator}+${condition.value}`
        )
        .join("-");
};

const getCachedApiResponse =
    (cache: ResultsCache) => (conditions: Condition[]) => {
        const key = getCacheKeyFromConditions(conditions);
        const cachedResults = cache[key as keyof ResultsCache];

        if (!cachedResults) {
            return null;
        }

        // check cache is not older than 5 minutes
        const cacheAge = Date.now() - cachedResults.timestamp;
        if (cacheAge / 60000 > 5) {
            return null;
        }

        return cachedResults;
    };

const getUsersWithConditions = async (
    conditions: Condition[],
    currentKey: string | null,
    rowsPerPage: number,
    setNextKey: (nextKey: string | null) => void,
    setCurrentKey: (prevKey: string | null) => void,
    page: number,
    cache: ResultsCache,
    setCache: (cache: ResultsCache) => void,
    setIsLoading: (isLoading: boolean) => void
) => {
    let hasTriedRefresh = false;
    const cachedResults = getCachedApiResponse(cache)(conditions);

    setNextKey(null);
    setCurrentKey(null);

    if (cachedResults && cachedResults.data?.length > 0) {
        const users = cachedResults.data.slice(
            page * rowsPerPage,
            (page + 1) * rowsPerPage
        );

        setIsLoading(false);
        return {
            users,
            total: cachedResults.count,
        };
    }

    const makeRequest = async (): Promise<{ users: User[]; total: number }> => {
        const token = getAccessToken();
        const endpointWithParams = addParamsToRequest({
            limit: rowsPerPage,
            lastKey: currentKey,
            conditions,
        });

        const apiResponse = await fetch(endpointWithParams, {
            headers: { ...authHeaders(token) },
            method: "GET",
        });

        const jsonResponse = await apiResponse.json();
        console.log("jsonResponse:", jsonResponse);

        // handle retry
        if (!apiResponse.ok && !hasTriedRefresh) {
            hasTriedRefresh = true;
            await refreshUserTokens();
            return await makeRequest();
        }

        // handle fail
        if (!apiResponse.ok) {
            setIsLoading(false);
            return {
                users: [],
                total: 0,
            };
        }

        // save results in cache
        const cacheKey = getCacheKeyFromConditions(conditions);
        const updatedCache = {
            ...cache,
            [cacheKey]: {
                data: jsonResponse.data,
                count: jsonResponse.count,
                timestamp: Date.now(),
            },
        };

        setCache(updatedCache);

        // client side pagination
        const users = jsonResponse.data.slice(
            page * rowsPerPage,
            (page + 1) * rowsPerPage
        );

        setIsLoading(false);
        return {
            users,
            total: jsonResponse.count,
        };
    };
    try {
        return await makeRequest();
    } catch (e) {
        console.error("Error, redirecting to login", e);
        // logoutAndRedirectToLogin();

        setIsLoading(false);
        return {
            users: [],
            total: 0,
        };
    }
};

const getUsersWithoutConditions = async (
    currentKey: string | null,
    rowsPerPage: number,
    setNextKey: (nextKey: string | null) => void,
    setCurrentKey: (prevKey: string | null) => void,
    setIsLoading: (isLoading: boolean) => void
) => {
    let hasTriedRefresh = false;

    const makeRequest = async (): Promise<{ users: User[]; total: number }> => {
        const token = getAccessToken();
        const endpointWithParams = addParamsToRequest({
            limit: rowsPerPage,
            lastKey: currentKey,
            conditions: [],
        });

        const apiResponse = await fetch(endpointWithParams, {
            headers: { ...authHeaders(token) },
            method: "GET",
        });

        const jsonResponse = await apiResponse.json();
        console.log("jsonResponse:", jsonResponse);

        // handle retry
        if (!apiResponse.ok && !hasTriedRefresh) {
            hasTriedRefresh = true;
            await refreshUserTokens();
            return await makeRequest();
        }

        // handle fail
        if (!apiResponse.ok) {
            setNextKey(null);
            setCurrentKey(null);

            setIsLoading(false);

            return {
                users: [],
                total: 0,
            };
        }

        // handle success
        // set next key or null if no more users
        setNextKey(jsonResponse.lastKey ?? null);

        setIsLoading(false);
        return {
            users: jsonResponse.data,
            total: jsonResponse.count,
        };
    };

    try {
        return await makeRequest();
    } catch (e) {
        console.error("Error, redirecting to login", e);
        // logoutAndRedirectToLogin();

        setIsLoading(false);
        return {
            users: [],
            total: 0,
        };
    }
};

export const getUsers = async (
    conditions: Condition[],
    currentKey: string | null,
    rowsPerPage: number,
    setNextKey: (nextKey: string | null) => void,
    setCurrentKey: (prevKey: string | null) => void,
    page: number,
    cache: ResultsCache,
    setCache: (cache: ResultsCache) => void,
    setIsLoading: (isLoading: boolean) => void
): Promise<{
    users: User[];
    total: number;
}> => {
    const nonEmptyConditions = filterEmptyConditions(conditions);
    const hasConditions = nonEmptyConditions.length > 0;

    setIsLoading(true);

    if (hasConditions) {
        return await getUsersWithConditions(
            nonEmptyConditions,
            currentKey,
            rowsPerPage,
            setNextKey,
            setCurrentKey,
            page,
            cache,
            setCache,
            setIsLoading
        );
    }

    return await getUsersWithoutConditions(
        currentKey,
        rowsPerPage,
        setNextKey,
        setCurrentKey,
        setIsLoading
    );
};

export const getUserById = async (id: string): Promise<User> => {
    let hasTriedRefresh = false;

    const makeRequest = async (): Promise<User> => {
        const token = getAccessToken();

        const params = new URLSearchParams();
        params.append("userId", id);

        const usersEndpoint = getApiEndpoint();
        const apiResponse = await fetch(
            `${usersEndpoint}?${params.toString()}`,
            {
                headers: { ...authHeaders(token) },
                method: "GET",
            }
        );

        if (!apiResponse.ok && !hasTriedRefresh) {
            hasTriedRefresh = true;
            await refreshUserTokens();
            return await makeRequest();
        }

        const jsonResponse: User = await apiResponse.json();

        if (!jsonResponse) {
            alert("User not found!");
            return {} as User;
        }

        return jsonResponse;
    };

    try {
        return await makeRequest();
    } catch (e) {
        console.error("Error, redirecting to login", e);

        // logoutAndRedirectToLogin();
        return {} as User;
    }
};

export enum KycAction {
    RESET = "reset",
    NO_MATCH = "no match",
    EXACT_MATCH = "exact match",
}
export const makeKycAction = async (
    userId: string,
    action: KycAction
): Promise<void> => {
    let hasTriedRefresh = false;

    const makeRequest = async (): Promise<void> => {
        const token = getAccessToken();

        const kycEndpoint = getKycEndpoint();
        const apiResponse = await fetch(`${kycEndpoint}/sp/kyc`, {
            headers: { ...authHeaders(token) },
            method: "POST",
            body: JSON.stringify({
                userId,
                op: action,
            }),
        });

        if (!apiResponse.ok && !hasTriedRefresh) {
            hasTriedRefresh = true;
            await refreshUserTokens();
            return await makeRequest();
        }
    };

    try {
        return await makeRequest();
    } catch (e) {
        console.error("Error", e);
    }
};
