import { User } from "../types";

export const prepObjectForCsvExport = (obj: Record<string, any>) => {
    const newObj = { ...obj };
    Object.keys(newObj).forEach((key) => {
        // if value is object
        if (
            typeof newObj[key] === "object" &&
            newObj[key] !== null &&
            !Array.isArray(newObj[key])
        ) {
            newObj[key] = prepObjectForCsvExport(newObj[key]);
        }

        if (Array.isArray(newObj[key])) {
            newObj[key] = JSON.stringify(newObj[key]).replace(/[\[\]"]/g, "");
        }
    });
    return newObj;
};

export const prepUsersForCsvExport = (users: User[]) => {
    return users.map((user) => prepObjectForCsvExport(user));
};
