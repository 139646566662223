import { getEnvironment } from "./get-environment";

const baseEndpoint = "kamoa.services/users";
const baseKycEndpoint = "kamoa.services";

export const getApiEndpoint = (): string => {
    // get environment from domain
    const environment = getEnvironment();

    // get api endpoint from environment
    return `https://${environment}.${baseEndpoint}`;
};

export const getKycEndpoint = (): string => {
    // get environment from domain
    const environment = getEnvironment();

    // get api endpoint from environment
    return `https://${environment}.${baseKycEndpoint}`;
};
