import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { RecoilRoot } from "recoil";
import { getUserCookie } from "./utils/get-user-cookie";
import { BrowserRouter } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { AdminUser, adminUserAtom } from "./global-state/user.atom";
import { deleteUserCookies } from "./utils/delete-user-cookie";
const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <RecoilRoot
            initializeState={(mutableSnapshot) => {
                const userCookieToken = getUserCookie();
                if (userCookieToken) {
                    const userObject: AdminUser = jwtDecode(userCookieToken);

                    // hack check email because GUGL!
                    if (userObject?.email?.includes("@kamoa.app")) {
                        mutableSnapshot.set(adminUserAtom, userObject);
                    } else {
                        deleteUserCookies();
                    }
                }
            }}
        >
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </RecoilRoot>
    </React.StrictMode>
);
