import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useSetRecoilState } from "recoil";
import { AdminUser, adminUserAtom } from "../global-state/user.atom";
import { deleteUserCookies } from "../utils/delete-user-cookie";

type Props = {
    user: AdminUser;
};

export const UserLogout: React.FC<Props> = ({ user }) => {
    const setUserAtom = useSetRecoilState(adminUserAtom);
    const handleLogout = () => {
        setUserAtom(null);
        deleteUserCookies();
    };

    const userName = user.email?.split("@")[0];
    return (
        <Stack direction={"row"}>
            <Typography style={{ lineHeight: 2.4 }} variant="h6">
                {userName?.toUpperCase()}
            </Typography>
            <Button
                variant="contained"
                color="secondary"
                sx={{ marginLeft: "1em" }}
                onClick={handleLogout}
            >
                Logout
            </Button>
        </Stack>
    );
};
