import { ZodError } from "zod";
import {
    Condition,
    multipleConditionsSchema,
} from "../global-state/conditions.atom";

const transformConditionValue = (value: string) => {
    switch (value.toLocaleLowerCase()) {
        case "true":
            return true;
        case "false":
            return false;
        case "null":
            return null;
        default:
            if (parseFloat(value)) {
                return parseFloat(value);
            }
            return value;
    }
};

const isEmptyCondition = (condition: Condition): boolean => {
    return (
        condition.attribute === "" &&
        condition.value === "" &&
        condition.operator === ""
    );
};

const removeEmptyConditionIfJustOne = (
    conditions: Condition[]
): Condition[] => {
    if (conditions.length === 1 && isEmptyCondition(conditions[0])) {
        return [];
    }
    return conditions;
};

export const getValidatedConditions = (
    conditions: Condition[]
): Condition[] | { error: ZodError } => {
    try {
        return multipleConditionsSchema
            .parse(removeEmptyConditionIfJustOne(conditions))
            .map((condition) => ({
                ...condition,
                value: transformConditionValue(condition.value as string),
            }));
    } catch (error) {
        return {
            error: error as ZodError,
        };
    }
};
