import { atom } from "recoil";
import { User } from "../types";

export interface ResultsCache {
    [key: string]: {
        data: User[];
        count: number;
        timestamp: number;
    };
}

export const resultCacheAtom = atom<ResultsCache>({
    key: "result-cache",
    default: {} as ResultsCache,
});
