import {
    Button,
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent,
    Stack,
    MenuItem,
    TextField,
    FormHelperText,
} from "@mui/material";
import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { Condition } from "../../global-state/conditions.atom";
import { validationErrorsAtom } from "../../global-state/validation-errors.atom";
import { getErrorsForCondition } from "../../utils/get-errors-for-filter";
import { CustomReactSelect } from "../input/react-select";

type Props = {
    condition: Condition;
    conditionIndex: number;
    removeCondition: (conditionIndex: number) => void;
    saveCondition: (condition: Condition, conditionIndex: number) => void;
    userProperties: string[];
};

export const UserFilterCondition: React.FC<Props> = ({
    condition,
    conditionIndex,
    saveCondition,
    removeCondition,
    userProperties,
}) => {
    const [attribute, setAttribute] = React.useState(condition.attribute);
    const [operator, setOperator] = React.useState(condition.operator);
    const [value, setValue] = React.useState(condition.value);
    const zodError = useRecoilValue(validationErrorsAtom);

    const conditionErrors = getErrorsForCondition(conditionIndex, zodError);

    // handle change of attribute on prop change
    useEffect(() => {
        setAttribute(condition.attribute);
        setOperator(condition.operator);
        setValue(condition.value);
    }, [condition]);

    const handleAttributeChange = (attribute: string) => {
        setAttribute(attribute);
        saveCondition({ attribute, operator, value }, conditionIndex);
    };

    const handleOperatorChange = (event: SelectChangeEvent) => {
        setOperator(event.target.value as string);
        saveCondition(
            { attribute, operator: event.target.value, value },
            conditionIndex
        );
    };

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        saveCondition(
            { attribute, operator, value: event.target.value },
            conditionIndex
        );
    };

    return (
        <Stack direction="row" sx={{ marginBottom: "1.5em" }}>
            <Stack sx={{ flex: 1 }} direction="row">
                <FormControl fullWidth sx={{ marginRight: "1em" }}>
                    <CustomReactSelect
                        onChange={handleAttributeChange}
                        value={attribute}
                        options={userProperties.map((property) => ({
                            label: property,
                            value: property,
                        }))}
                        placeholder="Attribute"
                        error={conditionErrors?.attribute}
                    />
                </FormControl>
                <FormControl fullWidth sx={{ marginRight: "1em" }}>
                    <InputLabel id={`$operator-select-${conditionIndex}-label`}>
                        Operator
                    </InputLabel>
                    <Select
                        labelId={`$operator-select-${conditionIndex}-label`}
                        id={`$operator-select-${conditionIndex}`}
                        value={operator}
                        label="Operator"
                        onChange={handleOperatorChange}
                        error={!!conditionErrors?.operator}
                    >
                        <MenuItem value={"GreaterThan"}>Greater Than</MenuItem>
                        <MenuItem value={"LowerThan"}>Lower Than</MenuItem>
                        <MenuItem value={"Equals"}>Equals</MenuItem>
                        <MenuItem value={"NotEquals"}>Not Equals</MenuItem>
                    </Select>
                    <FormHelperText
                        style={{
                            color: "red",
                        }}
                    >
                        {conditionErrors?.operator}
                    </FormHelperText>
                </FormControl>
                <FormControl fullWidth sx={{ marginRight: "1em" }}>
                    <TextField
                        id={`$value-select-${conditionIndex}`}
                        label="Value"
                        variant="outlined"
                        value={value}
                        onChange={handleValueChange}
                        error={!!conditionErrors?.value}
                        helperText={conditionErrors?.value}
                    />
                </FormControl>
            </Stack>
            <Stack sx={{ minWidth: "250px" }} direction="row">
                <Button
                    variant="contained"
                    sx={{ width: "100px" }}
                    onClick={() => {
                        removeCondition(conditionIndex);
                    }}
                    color="error"
                >
                    Delete
                </Button>
            </Stack>
        </Stack>
    );
};
