import { Box, Button } from "@mui/material";
import React, { useEffect } from "react";
import { CSVLink } from "react-csv";
import { useRecoilState, useSetRecoilState } from "recoil";
import { ZodError } from "zod";

import { getUsers } from "../components/users-table-section/get-users";
import { UsersFilter } from "../components/users-table-section/users-filter";
import { UsersTable } from "../components/users-table-section/users-table";
import { Condition, conditionsAtom } from "../global-state/conditions.atom";
import { currentKeyAtom } from "../global-state/current-key.atom";
import { isLoadingUsers } from "../global-state/is-loading-users.atom";
import { nextKeyAtom } from "../global-state/next-key.atom";
import { pageAtom } from "../global-state/page.atom";
import { resultCacheAtom } from "../global-state/results-cache.atom";
import { rowsPerPageAtom } from "../global-state/rows-per-page.atom";
import { validationErrorsAtom } from "../global-state/validation-errors.atom";
import {
    User,
    userProperties,
    userPropertiesDetailed,
    userPropertiesForQuerying,
} from "../types";
import { prepUsersForCsvExport } from "../utils/prep-for-csv-export";
import { getValidatedConditions } from "../utils/validate-conditions";

export const UsersTablePage: React.FC = () => {
    const [users, setUsers] = React.useState<User[]>([]);
    const [usersTotal, setUsersTotal] = React.useState(0);
    const [page, setPage] = useRecoilState(pageAtom);
    const [currentKey, setCurrentKey] = useRecoilState(currentKeyAtom);
    const [nextKey, setNextKey] = useRecoilState(nextKeyAtom);
    const [rowsPerPage, setRowsPerPage] = useRecoilState(rowsPerPageAtom);
    const [conditions, setConditions] = useRecoilState(conditionsAtom);
    const setValidationErrors = useSetRecoilState(validationErrorsAtom);
    const [cache, setCache] = useRecoilState(resultCacheAtom);
    const [isLoading, setIsLoading] = useRecoilState(isLoadingUsers);

    const validateInputAndFetchUsers = async () => {
        const parsedConditions = getValidatedConditions(conditions);
        if ((parsedConditions as { error: ZodError }).error) {
            return setValidationErrors(
                (parsedConditions as { error: ZodError }).error
            );
        }

        setValidationErrors(null);

        const { users, total } = await getUsers(
            conditions,
            currentKey,
            rowsPerPage,
            setNextKey,
            setCurrentKey,
            page,
            cache,
            setCache,
            setIsLoading
        );

        setUsers(users);
        setUsersTotal(total);
    };

    useEffect(() => {
        validateInputAndFetchUsers();
        // we don't want to execute when conditions change, that is reserved for the execute query button
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage]);

    const handleChangePage = (_: unknown, newPage: number) => {
        const isGoingForward = newPage > page;
        if (isGoingForward) {
            setCurrentKey(nextKey);
            setPage(newPage);
        } else {
            setCurrentKey(null);
            setNextKey(null);
            setPage(0);
        }
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setCurrentKey(null);
        setNextKey(null);
    };

    const handleAddNewCondition = () => {
        setConditions([
            ...conditions,
            { attribute: "", operator: "", value: "" },
        ]);
    };

    const handleRemoveCondition = (conditionIndex: number) => {
        const newConditions = conditions.filter(
            (_, index) => index !== conditionIndex
        );

        const removedLastCondition = newConditions.length === 0;
        if (removedLastCondition) {
            newConditions.push({
                attribute: "",
                operator: "",
                value: "",
            });
            setPage(0);
            setValidationErrors(null);
        }

        setConditions([...newConditions]);
    };

    const handleUpdateCondition = (
        condition: Condition,
        conditionIndex: number
    ) => {
        setConditions(
            conditions.map((_, index) => {
                if (index === conditionIndex) {
                    return condition;
                }
                return _;
            })
        );
    };

    const handleExecuteQuery = () => {
        validateInputAndFetchUsers();
    };

    return (
        <Box sx={{ padding: "2em" }}>
            <UsersFilter
                addCondition={handleAddNewCondition}
                conditions={conditions}
                removeCondition={handleRemoveCondition}
                saveCondition={handleUpdateCondition}
                executeQuery={handleExecuteQuery}
                userProperties={userPropertiesForQuerying}
            />
            <CSVLink
                headers={userPropertiesDetailed}
                style={{ textDecoration: "none", float: "right" }}
                data={prepUsersForCsvExport(users)}
                filename="users.csv"
            >
                <Button
                    style={{
                        marginBottom: "1em",
                    }}
                    variant="contained"
                    color="info"
                >
                    Download CSV
                </Button>
            </CSVLink>
            <UsersTable
                users={users as unknown as User[]}
                isLoading={isLoading}
                totalUsersCount={usersTotal}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                userProperties={userProperties}
            />
        </Box>
    );
};
