import { Button, Stack } from "@mui/material";
import React from "react";
import { Condition } from "../../global-state/conditions.atom";
import { UserFilterCondition } from "./user-filter-condition";

type Props = {
    conditions: Condition[];
    removeCondition: (conditionIndex: number) => void;
    addCondition: () => void;
    saveCondition: (condition: Condition, conditionIndex: number) => void;
    executeQuery: () => void;
    userProperties: string[];
};

export const UsersFilter: React.FC<Props> = ({
    conditions,
    removeCondition,
    saveCondition,
    addCondition,
    executeQuery,
    userProperties,
}) => {
    return (
        <Stack sx={{ marginBottom: "6em" }}>
            {conditions.map((condition, index) => {
                return (
                    <UserFilterCondition
                        key={index}
                        condition={condition}
                        conditionIndex={index}
                        removeCondition={removeCondition}
                        saveCondition={saveCondition}
                        userProperties={userProperties}
                    />
                );
            })}
            {/* <Button
                variant="contained"
                sx={{
                    width: "500px",
                    marginTop: "1em",
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
                onClick={addCondition}
            >
                Add
            </Button> */}
            <Button
                variant="contained"
                sx={{
                    width: "500px",
                    marginTop: "2em",
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
                onClick={executeQuery}
                color="warning"
            >
                Run
            </Button>
        </Stack>
    );
};
