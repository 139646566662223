export const deepTransformTimeStampToDate = (obj: Record<string, any>) => {
    const newObj = { ...obj };
    Object.keys(newObj).forEach((key) => {
        // if value is object
        if (typeof newObj[key] === "object" && newObj[key] !== null) {
            newObj[key] = deepTransformTimeStampToDate(newObj[key]);
        }

        if (key.toLocaleLowerCase().includes("timestamp")) {
            newObj[key] = new Date(newObj[key]).toLocaleString();
        }
    });
    return newObj;
};
