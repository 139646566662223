import { getOauthInfo } from "./get-hosted-ui-url";
import { getRefreshToken } from "./get-refresh-token";

export const refreshUserTokens = async () => {
    const refreshToken = getRefreshToken();

    if (!refreshToken) {
        throw new Error("No refresh token found");
    }
    // get tokens from aws cognito
    const { idToken, accessToken } = await getTokensFromRefresh(refreshToken);

    const date30DaysFromNow = new Date();
    date30DaysFromNow.setTime(new Date().getTime() + 30 * 24 * 60 * 60 * 1000);

    document.cookie = `id_token=${idToken}; expires=${date30DaysFromNow.toUTCString()}; path=/`;
    document.cookie = `access_token=${accessToken}; expires=${date30DaysFromNow.toUTCString()}; path=/`;
};

export const getTokensFromRefresh = async (
    refreshToken: string
): Promise<{
    idToken: string;
    accessToken: string;
}> => {
    const { clientId, url } = getOauthInfo();

    const res = await fetch(url, {
        method: "POST",
        headers: new Headers({
            "content-type": "application/x-www-form-urlencoded",
        }),
        body: Object.entries({
            grant_type: "refresh_token",
            client_id: clientId,
            refresh_token: refreshToken,
            redirect_uri: `${window.location.origin}/login-auth`,
        })
            .map(([k, v]) => `${k}=${v}`)
            .join("&"),
    });

    if (!res.ok) {
        throw new Error(await res.text());
    }

    const tokens = await res.json();
    return {
        idToken: tokens.id_token,
        accessToken: tokens.access_token,
    };
};
